import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import PricingCard from '../components/PricingCard';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import servicesData from '../data/services-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Изучение испанского и практика коммуникативного навыка
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Мы верим в то, что только необходимая практика помогает освоить язык. Опыт показывает,
            что обычные программы зачастую не дают желаемого результата, так как растянуты во
            времени и их тяжело совмещать с ежедневной рутиной.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">Попробовать бесплатно</Button>
          </p>
          <p className="mt-4 text-gray-600">Принимаю условия</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Что будем делать?</h2>
        <div className="flex flex-wrap flex-row sm:flex-row sm:-mx-3 mt-12 text-left">
          {servicesData.map(serviceCard => (
            <div key={serviceCard.title} className="flex-1 lg:flex-3 flex-grow px-3">
              <Card
                className="mb-8"
                title={serviceCard.title}
                description={serviceCard.description}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Практический курс</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Книга для начинающих. Основана на всех конструкциях настоящего времени. Состоит из
            теоретической грамматики языкового уровня А1 и практических заданий.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Аудио курс</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Состоит из 19 аудио уроков на самые основные темы. Можно слушать и повторять. В аудио
            курсе собраны наиболее часто применимые речи фразы повседневного испанского. Тренирует
            разговорную языковую базу уровня А1-А2.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Тренеры</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Эвелина тренирует с 2008 года. Обучила более 1000 студентов в Skype и в различных
            центрах Минска, Москвы, Санкт-Петербурга и Калининграда - в том числе - в БФУ им. Канта.
            Работает по авторским методикам.
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Вика, тренирует с 2015 года. Обучила более 300 студентов в Skype, Zoom и в языковых
            школа города Санкт-Петербург. Работает по авторским методикам. Уклон в обучении делает
            на разговорную речь.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Результаты</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="1 000+" secondaryText="Участников" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="20" secondaryText="Городов мира" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="30+" secondaryText="Семей переехало в Испанию" />
          </div>
        </div>
      </div>
    </section>
    <section id="price" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Стоимость</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          <PricingCard className="mb-8" title="Спринт 45 дней" description="" />
          <p className="mt-8"></p>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Отзывы</LabelText>
        <div className="flex flex-col md:flex-column md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Готов начать спринт?</h3>
      <p className="mt-8 text-xl font-light">
        Присоединяйся к нам, попробуй бесплатно несколько уроков и оставайся на целый спринт!
      </p>
      <p className="mt-8">
        <Button size="xl">Начать</Button>
      </p>
    </section>
  </Layout>
);
