export default [
  {
    title: 'Это — практический курс',
    description:
      'Мы тренируем коммуникативный навык с первого дня даже не зная ни одного слова. Хотя вот список слов, которые ты точно знаешь.'
  },
  {
    title: 'Запоминаем с мнемотехниками',
    description: 'Научим системе быстрого и эффективного запоминания.'
  },
  {
    title: 'Прокачиваем лексику',
    description:
      'Мы рассказываем откуда брать лексику, как ее встраивать в предложения. От билингвальных книг до постов в Инстаграме.'
  },
  {
    title: 'Интервально повторяем',
    description:
      'Ключевой метод в изучении языка может быть скучным, однако мы делаем все, чтобы новые повторения приносили новый смысл.'
  },
  {
    title: 'Смотрим Youtube и обсуждаем',
    description:
      'Важно понимать чем живет общество сегодня. Ютюб — прекрасный способ узнать актуальное в культуре Испании.'
  },
  {
    title: 'Социально взаимодействуем',
    description:
      'Подписываемся на интересных инфлюенсеров в соц сетях, ищем друзей на сайтах типа italki.com.'
  }
];
